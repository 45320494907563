.app__about {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__profiles {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 2rem;
  width: 100%;
}

.app__profile-item {
  width: 40%;
  flex-direction: column;
  margin: 2rem;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: #fff;
  color: black;
  transition: all 0.3s ease;
  height: 100%;

  @media screen and (min-width: 2000px) {
    width: 470px;
    padding: 1.25rem;
    border-radius: 0.75rem;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
    margin: 1rem;
    height: initial;
  }
}

.app__about-title {
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  color: var(--black-color);

  @media screen and (min-width: 2000px) {
    font-size: 3rem;
  }

  @media screen and (max-width: 450px) {
    font-size: 1rem;
  }
}

p.p-about {
  font-size: 1rem;
}
