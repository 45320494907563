#home {
  position: relative;

  .app__wrapper {
    padding: 0;

    .copyright {
      display: none;
    }
  }
}

.app__header {
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  padding: 5rem 2rem 0;
  display: flex;
  justify-content: space-around;

  @media screen and (min-width: 2000px) {
    padding-top: 8rem;
  }
  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
  @media screen and (max-width: 400px) {
    padding: 6rem 1rem 2rem;
  }
}

.app__header-profile {
  margin: 15px;
  img {
    height: 200px;
    border-radius: 50%;
    border: 1px solid white;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  }
}

.app__header-introduction {
  p {
    font-size: 4rem;
  }

  h1 {
    font-size: 6rem;
    text-align: left;
  }
}

@media screen and (max-width: 400px) {
  .app__header-introduction {
    p {
      font-size: 2rem;
      text-align: center;
    }

    h1 {
      font-size: 4rem;
      text-align: left;
      text-align: center;
    }
  }
}
